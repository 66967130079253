import React from "react";
import { Col } from "react-bootstrap";
import { Icon } from "@iconify/react";
import instagramFill from "@iconify/icons-akar-icons/instagram-fill";
import twitterFill from "@iconify/icons-akar-icons/twitter-fill";

class SocialIcons extends React.Component {
  state = {};
  render() {
    return (
      <Col xs={6} className="col-center">
        <a
          href="https://www.instagram.com/beawareco2/"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            icon={instagramFill}
            className="mr-4"
            id="insta-icon"
            style={{ fontSize: "30px" }}
          />
        </a>
        <a
          href="https://twitter.com/beawareco2"
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            id="twitter-icon"
            icon={twitterFill}
            style={{ fontSize: "30px" }}
          />
        </a>
      </Col>
    );
  }
}

export default SocialIcons;
