import React from "react";
import { Row, Col, Container } from "react-bootstrap";

class Mail extends React.Component {
  state = {};
  render() {
    return (
      <div>
        <Container>
          <Row className="mail">
            <Col xs={10} md={6}>
              <p className="text-center">
                We are a community of people teaching ways to reduce CO2
                emissions in our social cycle via simple activities and
                sustainable products.
              </p>
              <p className="text-center">To join our community:</p>
            </Col>
          </Row>
          <Row className="sign-up">
            <Col xs={10} md={6}>
              {/* <form action="https://formspree.io/f/xwkakzvr" method="POST">
                <div className="col-center wrap-sm">
                  <input
                    type="email"
                    placeholder="Email"
                    name="_replyto"
                    className="mr-3"
                    id="input-email"
                  ></input>
                  <button
                    className="btn btn-primary cta"
                    id="cta-sign"
                    type="submit"
                  >
                    Count me in!
                  </button>
                </div>
              </form> */}
              <div className="col-center wrap-sm">
                <a href="https://discord.gg/fD8K326uBe" target="_blank" rel="noreferrer">
                  <button
                    className="btn btn-primary cta"
                    id="cta-sign"
                    type="submit"
                  >
                    Count me in!
                  </button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Mail;
