import React from "react";
import { Col, Row, Container } from "react-bootstrap";

class Intro extends React.Component {
  state = {};
  render() {
    return (
      <Container>
        <Row className="intro">
          <Col xs={10} md={6}>
            <p className="intro-p">
              BeawareCO2 is a social-environmental initiative to raise awareness
              of the public on CO2 emission
            </p>
            <a
              href="https://beawareco2.medium.com"
              target="_blank"
              rel="noreferrer"
              id="blog-link"
            >
              Check out our blog!
            </a>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Intro;
