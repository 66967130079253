import React from "react";
import { Container, Row } from "react-bootstrap";

class Footer extends React.Component {
  state = {};
  render() {
    return (
      <Container>
        <Row className="footer">
          <p>
            Built by{" "}
            <a
              href="https://nemria.com"
              target="_blank"
              rel="noreferrer"
              id="nemria-link"
            >
              Nemria
            </a>
          </p>
        </Row>
      </Container>
    );
  }
}

export default Footer;
