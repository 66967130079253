import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Icon } from "@iconify/react";
import refreshIcon from "@iconify/icons-ion/refresh";

class Awareness extends React.Component {
  state = {};

  render() {
    return (
      <Container>

      <Row className="header">
        <Col xs={10} md={6} className="col-center">
          <div className="awareness-card refresh-icon-hover" onClick={this.props.onClick}>
            <div>
              <p className="awareness">
                {this.props.entry}
              </p>
            </div>
            <div className="refresh-icon">
              <span className="refresh-icon-hover" onClick={this.props.onClick}>
                <Icon icon={refreshIcon} style={{ fontSize: "30px" }} />
              </span>
            </div>
          </div>
        </Col>
      </Row>
      </Container>
    );
  }
}

export default Awareness;
