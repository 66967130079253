import React from "react";
import "./App.css";
import Awareness from "./Components/Awareness";
import Footer from "./Components/Footer";
import Header from "./Components/Header.js";
import Intro from "./Components/Intro";
import Mail from "./Components/Mail";
import data from "./data/data.json";
import themes from "./data/themes.json";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function updateTheme() {
  let theme = getRandomInt(themes.length);
  let color = themes[theme].color;
  let bgcolor = themes[theme].bgcolor;
  document.body.style =
    "background-color: " + bgcolor + "; color: " + color + ";";
  // const links = document.getElementsByTagName("A");

  // for (let i = 0; i < links.length; i++) {
  //   links[i].style = "color: " + color + ";";
  //   console.log(links[i]);
  // }
  document.getElementById("nemria-link").style =
    "color: " + color + "; border:none;";
    document.getElementById("blog-link").style =
    "color: " + color + "; border:none;";
  document.getElementById("cta-sign").style =
    "background-color: " + color + "; color: " + bgcolor + "; border:none;";
  // document.getElementById("input-email").style =
  //   "background-color: " +
  //   bgcolor +
  //   "; border-color: " +
  //   color +
  //   "; color: " +
  //   color +
  //   ";";
  // document.getElementById("input-email").style.setProperty("--c", color);
  document.getElementById("insta-icon").style.setProperty("--c", color);
  document.getElementById("twitter-icon").style.setProperty("--c", color);
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entry: this.entry(),
    };
    this.handleClick = this.handleClick.bind(this);
  }

  entry() {
    return data[getRandomInt(data.length)].entry;
  }

  updateEntry() {
    this.setState(() => {
      return {
        entry: this.entry(),
      };
    });
  }

  handleClick() {
    console.log("hello");
    updateTheme();
    this.setState(() => {
      return {
        entry: this.entry(),
      };
    });
  }

  componentDidMount() {
    updateTheme();
    this.updateEntry();
  }

  render() {
    return (
      <div className="App">
        <Header />
        <Intro />
        <Awareness onClick={this.handleClick} entry={this.state.entry} />
        <Mail />
        <Footer />
      </div>
    );
  }
}

export default App;
