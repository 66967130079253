import React from "react";
import { Col, Row, Container} from "react-bootstrap";
import SocialIcons from "./SocialIcon";

class Header extends React.Component {
  state = {};
  render() {
    return (
      <Container>
        <Row className='header'>
          <Col xs={3}>
  
          </Col>
          <Col xs={6} className='col-center'>
            <h1 className='brand h2'>#beawareCO2</h1>
          </Col>
          <Col xs={3} className="col-cta">
            
          </Col>
        </Row>
        <Row className='social-icons'>
          <SocialIcons />
        </Row>
      </Container>
    );
  }
}

export default Header;
